export function shorten(str, maxLen, separator = ' ') {
  if (typeof str === 'undefined') {
    return '';
  }
  if (str.length <= maxLen) {
    return str;
  }
  return str.substr(0, str.lastIndexOf(separator, maxLen))
    .replace(/(\r\n\t|\n|\r\t)/gm,"")
    +  '\u2026';
}

export function place(city, state, country) {
  var address = '';
  if (city) {
    address += city;
  }
  if (state) {
    address += city ? ', ' + state : state;
  }
  if (country) {
    address += city || state ? ', ' + country : country;
  }
  return address;
}

export function encode(str) {
  return encodeURIComponent(str);
}

// Source: https://stackoverflow.com/a/8260383
export function youTubeParser(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match&&match[7].length==11)? match[7] : false;
}
