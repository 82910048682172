import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import config from '../../utils/config';
import { shorten } from '../../utils/helpers';

const Seo = ({
  title = config.siteTitle,
  description = config.siteDescription,
  slug = '',
  image = config.siteImage,
} = {
  title: config.siteTitle,
  description: config.siteDescription,
  slug: '',
  image: config.siteImage,
}) => {
  const url = slug
    ? `${config.siteUrl}${config.pathPrefix}${slug}`
    : `${config.siteUrl}${config.pathPrefix}`;

  if (title !== config.siteTitle) {
    title = `${title} | ${config.siteTitle}`;
  }

  if (description !== config.siteDescription) {
    description = shorten(description, 155);
  }

  if (image !== config.siteImage) {
    image = config.siteUrl + image;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: config.siteLanguage,
        prefix: 'og: http://ogp.me/ns#',
      }}
    >
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Google verify ownership */}
      <meta name="google-site-verification" content="joWQsKqWn0LPpPaFliLqmYDTF-zDpHUuBvQJ9j85__0" />
      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta
        name="twitter:site"
        content={config.twitterAccount ? config.twitterAccount : ''}
      />
      <meta
        name="twitter:creator:id"
        content={config.twitterAccount ? config.twitterAccount : ''}
      />
    </Helmet>
  );
};

Seo.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default Seo;
